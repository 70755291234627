import React from 'react'
import Layout from '../../components/layout'
import FacebookIcon from '../../images/icons/logo-facebook.svg'
import LinkedinIcon from '../../images/icons/logo-linkedin.svg'
import YoutubeIcon from '../../images/icons/logo-youtube.svg'
import MailIcon from '../../images/icons/mail.svg'
import Cookies from 'universal-cookie'

class ContactPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: '',
      lastName: '',
      email: '',
      companyName: '',
      notes: '',
    }
  }

  submitEmail = event => {
    // console.log(process.env.IS_LIVE);
    if (process.env.IS_LIVE === 'true') {
      const cookies = new Cookies()
      var obj = {}
      obj.fields = []

      var firstNameObj = {}
      firstNameObj.name = 'firstname'
      firstNameObj.value = this.state.firstName

      var lastNameObj = {}
      lastNameObj.name = 'lastname'
      lastNameObj.value = this.state.lastName

      var emailObj = {}
      emailObj.name = 'email'
      emailObj.value = this.state.email

      var companyObj = {}
      companyObj.name = 'company'
      companyObj.value = this.state.companyName

      var partnerNoteObj = {}
      partnerNoteObj.name = 'partner_notes'
      partnerNoteObj.value = this.state.notes

      obj.fields.push(firstNameObj, lastNameObj, emailObj, companyObj, partnerNoteObj)

      obj.context = {}
      // obj.context.hutk = this.getCookie('hubspotutk');
      obj.context.hutk = cookies.get('hubspotutk')
      obj.context.pageUri = 'www.thebreathingiq.com/contact'
      obj.context.pageName = 'Contact/Partner Page'
      console.log(obj);
      fetch(
        'https://api.hsforms.com/submissions/v3/integration/submit/8733114/6443243d-7fe4-4fcb-86ab-f719c356b50e',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(obj),
        }
      )
    }
  }

  handleChange = event => {
    const label = event.target.name
    const value = event.target.value
    this.setState({ [label]: value })
  }

  render() {
    return (
      <section className="section-padding biq-bg-prim min-h-screen">
        <div className="container">
          <h1 className="biq-text-white mt-5  xl:mt-20">We'd love to hear from you!</h1>
          <div className="text-3xl biq-text-gray text-center">
            Join our trusted community of partners.
          </div>
          <div
            className="biq-bg-white w-full mt-10 lg:mt-20 rounded-lg flex flex-col lg:flex-row"
          >
            <div className="flex flex-col w-full lg:w-2/6 justify-between biq-bg-prim-light p-10 rounded-lg">
              <div className="flex flex-col">
                <div className="flex font-bold biq-text-xl biq-text-white">
                  Contact Information
                </div>
                <div className="flex mt-6 text-lg biq-text-gray">
                  Please fill out the form and we will get back to you as soon as possible!
                </div>
                <div className="flex flex-row place-items-center mt-5">
                  <MailIcon fill="#faf7f2" height="25px" width="25px" />
                  <a
                    href="mailto:hello@thebreathingiq.com"
                    className="flex text-lg justify-center p-4 biq-text-white"
                  >
                    hello@thebreathingiq.com
                  </a>
                </div>
              </div>
              <div className="flex flex-row justify-center lg:justify-start place-items-center mt-16">
                <a
                  href="https://www.facebook.com/thebreathingiq"
                  target="_blank"
                  className="flex p-4"
                >
                  <FacebookIcon fill="#faf7f2" height="32px" width="32px" />
                </a>
                <a
                  href="https://www.youtube.com/channel/UCjzYQ_c-QlN6gwGQWYnVERQ"
                  target="_blank"
                  className="flex p-4"
                >
                  <YoutubeIcon fill="#faf7f2" height="32px" width="32px" />
                </a>
                <a
                  href="https://www.linkedin.com/company/the-breathingiq/"
                  target="_blank"
                  className="flex p-4"
                >
                  <LinkedinIcon fill="#faf7f2" height="32px" width="32px" />
                </a>
              </div>
            </div>
            <div className="flex flex-col p-10 w-full lg:w-4/6">
              <div className="flex flex-row flex-wrap">
                <div className="flex flex-col w-full sm:w-1/2 px-2 mt-6 xl:mt-0">
                  <div className="text-lg">First Name</div>
                  <input
                    className="rounded-lg text-lg py-2 px-4 bg-opacity-25 border-2 border-solid mt-2"
                    type="text"
                    name="firstName"
                    value={this.state.firstName}
                    onChange={this.handleChange}
                    placeholder="John"
                  />
                </div>
                <div className="flex flex-col w-full sm:w-1/2 px-2 mt-6 xl:mt-0">
                  <div className="text-lg">Last Name</div>
                  <input
                    className="rounded-lg text-lg py-2 px-4 bg-opacity-25 border-2 border-solid mt-2"
                    type="text"
                    name="lastName"
                    value={this.state.lastName}
                    onChange={this.handleChange}
                    placeholder="Doe"
                  />
                </div>
              </div>
              <div className="flex flex-row flex-wrap">
                <div className="flex flex-col w-full sm:w-1/2 px-2 mt-6">
                  <div className="text-lg">Email</div>
                  <input
                    className="rounded-lg text-lg py-2 px-4 bg-opacity-25 border-2 border-solid mt-2"
                    type="text"
                    name="email"
                    value={this.state.email}
                    onChange={this.handleChange}
                    placeholder="john.doe@thebreathingiq.com"
                  />
                </div>
                <div className="flex flex-col w-full sm:w-1/2 px-2 mt-6">
                  <div className="text-lg">Company Name</div>
                  <input
                    className="rounded-lg text-lg py-2 px-4 bg-opacity-25 border-2 border-solid mt-2"
                    type="text"
                    name="companyName"
                    value={this.state.companyName}
                    onChange={this.handleChange}
                    placeholder="John Doe Co."
                  />
                </div>
                {/* <div className="flex flex-col w-1/2 px-2">
                  <div className="text-lg">Phone Number</div>
                  <input
                    className="rounded-lg py-2 px-4 bg-opacity-25"
                    type="number"
                    name="exhale"
                    placeholder="(123)456-7891"
                  />
                </div> */}
              </div>
              <div className="flex flex-row flex-wrap mt-6">
                  <div className="flex flex-col w-full px-2">
                    <div className="text-lg">Tell us a little bit about yourself.</div>
                    <textarea
                    style={{minHeight: '130px'}}
                      className="rounded-lg text-lg py-2 px-4 bg-opacity-25 border-2 border-solid mt-2"
                      type="text"
                      name="notes"
                      value={this.state.notes}
                      onChange={this.handleChange}
                      placeholder=""
                    ></textarea>
                  </div>
              </div>
              {/* <div className="flex flex-col mt-6">
                <div className="flex px-2">How did you find out about us?</div>
                <div className="flex flex-row mt-6 w-full justify-around">
                  <div className="flex place-items-center">
                    <input
                      className="flex px-4"
                      type="radio"
                      id="male"
                      name="gender"
                      value="male"
                    />
                    <label className="flex px-4" for="male">
                      Social Media
                    </label>
                  </div>
                  <div className="flex place-items-center">
                    <input
                      className="flex px-4"
                      type="radio"
                      id="male"
                      name="gender"
                      value="male"
                    />
                    <label className="flex px-4" for="male">
                      A Friend
                    </label>
                  </div>
                  <div className="flex place-items-center">
                    <input
                      className="flex px-4"
                      type="radio"
                      id="male"
                      name="gender"
                      value="male"
                    />
                    <label className="flex px-4" for="male">
                      Reading an article
                    </label>
                  </div>
                  <div className="flex place-items-center">
                    <input
                      className="flex px-4"
                      type="radio"
                      id="male"
                      name="gender"
                      value="male"
                    />
                    <label className="flex px-4" for="male">
                      Podcast
                    </label>
                  </div>
                </div>
                <div className="flex flex-row mt-6">
                  <div className="flex flex-col w-full px-2">
                    <div className="text-lg">Message</div>
                    <textarea
                      className="rounded-lg py-2 px-4 bg-opacity-25 border-2 border-solid"
                      rows="4"
                      cols="50"
                      placeholder="Write your message..."
                    ></textarea>
                  </div>
                </div>
              </div> */}
              <div className="flex w-full justify-end mt-16">
                  <button className="btn-dark py-4 px-6 rounded-lg" onClick={this.submitEmail}>Send Contact Info</button>
              </div>
            </div>
          </div>
        </div>
      </section>
      //   <Layout>
    
      //   </Layout>
    )
  }

}


export default ContactPage